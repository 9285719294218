import * as React from "react";
import axios from 'axios';
import { useState, useEffect } from "react";
import {useNavigate } from 'react-router-dom';
import styled from "styled-components";
import FolderView from "../components/FolderView";
import FolderIcon from '@mui/icons-material/Folder';
import AddIcon from '@mui/icons-material/Add';
import TurnLeftIcon from '@mui/icons-material/TurnLeft';
import { useLocation } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { APIURL, HOSTNAME } from '../Global';
import PersonIcon from '@mui/icons-material/Person';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'; 
import GroupsIcon from '@mui/icons-material/Groups';
import PeopleIcon from '@mui/icons-material/People';
import AddchartIcon from '@mui/icons-material/Addchart';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import EventNoteIcon from '@mui/icons-material/EventNote';
import logout from '../assets/logout.png';
import MenuIcon from '@mui/icons-material/Menu';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import GroupDataTab from '../components/GroupsTab';
import MembersTab from '../components/MembersTab';
import ReportsTab from '../components/ReportsTab';
import SurveysTab from "../components/SurveysTab";
import { Grid } from '@material-ui/core';
import { Select, MenuItem } from '@mui/material';
import { FaEdit, FaTrash } from 'react-icons/fa';
import DataTable from 'react-data-table-component';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
function Dashboard() {
    const location = useLocation();
    const navigate = useNavigate();


  window.history.pushState(null, null, window.location.href);
window.history.back();
window.onpopstate = () => window.history.forward();

    const token = localStorage.getItem('codeDcUserToken');

    const storedUserName = localStorage.getItem('codeDcUsername');
    const storedUserEmail = localStorage.getItem('codeDcUserEmail');
    const storedUserRole = localStorage.getItem('codeDcUserRole');

    const [treeData, setTreeData] = useState([]);
    const [isPopupOpenNew, setIsPopupOpenNew] = useState(false);
    const [selectedParentId, setSelectedParentId] = useState(null);
    const [newEntry, setNewEntry] = useState('');
    const [groupType, setGroupType] = useState(null);
    const [responseMessage, setResponseMessage] = useState('');
    const [selectedNode, setSelectedNode] = useState(null);
    const [selectedGroupType, setSelectedGroupType] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState('');
    const [breadCrumbs, setBreadCrumbs] = useState('');
    const [selectedGroupTypeName, setSelectedGroupTypeName] = useState('');
    const [childGroupTypeName, setChildGroupTypeName] = useState('');
    const [selectedGroupId, setSelectedGroupId] = useState('');
    const [teamMembers, setTeamMembers] = useState([]);
    const [expanded, setExpanded] = useState([]); // Manage expanded state here
    const [isAddUserPopupOpen, setIsAddUserPopupOpen] = useState(false);
    const [newUser, setNewUser] = useState({
      usernameadmin: '',
      passwordadmin: '',
      confirm_password: '',
      first_name: '',
      last_name: '',
      email: '',
      mobile: '',
      group_id: selectedGroupId,
      role: 0
    });

    const [addUserError, setAddUserError] = useState('');
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isUserDetailsPopupOpen, setIsUserDetailsPopupOpen] = useState(false);
    const [isProfileEditOpen, setIsProfileEditOpen] = useState(false);
const [profileFields, setProfileFields] = useState({
  user_id: '',
  username: '',
  first_name: '',
  last_name: '',
  dob: '',
  gender: '',
  email: '',
  phone: '',
  houseName: '',
  role: ''  
});
    const [isSetPasswordPopupOpen, setIsSetPasswordPopupOpen] = useState(false);
    const [newpasswordnew, setNewPasswordNew] = useState('');
    const [confirmpasswordconfirm, setConfirmPasswordConfirm] = useState('');
    const ToggleButton = styled.button`
      background: none;
      border: none;
      color: #2456ae;
      font-size: 1.5rem;
      cursor: pointer;
      padding: 5px;

      @media (min-width: 1001px) {
        display: none;
      }
    `;
   
    const [leftSidebarOpen, setLeftSidebarOpen] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [tabIndexs, setTabIndexs] = useState(false);
    const [contacts, setContacts] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [newContact, setNewContact] = useState({
    group: selectedGroupId,
    polling_booth_number: '',
    polling_booth_name: '',
    sl_no: '',
    house_no: '',
    house_name: '',
    name: '',
    guardian_name: '',
    dob: '',
    age: '',
    gender: 'choose an option',
    epic_no: '',
    eligible_to_vote:false
  });

  
  
 
  const [currentContact, setCurrentContact] = useState(null);
  const initialContactState = {
    group: selectedGroupId,
    polling_booth_number: '',
    polling_booth_name: '',
    sl_no: '',
    house_no: '',
    house_name: '',
    name: '',
    guardian_name: '',
    dob: '',
    age: '',
    gender: 'choose an option',
    epic_no: '',
    eligible_to_vote:false
  };

  const openModal = () => {
    setNewContact(initialContactState);
    setFormErrors({}); 
    setModalOpen(true);
    
  };
  const [formErrors, setFormErrors] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  
  const MARITAL_STATUS_CHOICES = ['single', 'married', 'divorced', 'widowed'];
const GENDER_CHOICES = ['choose an option',['M', 'Male'], ['F', 'Female'], ['T', 'Transgender']];
const PENSION_CHOICES = ['yes', 'no'];
const VOTE_CHOICES = [ 'yes', 'no'];
const [isLastLevel, setIsLastLevel] = useState(false);
const [errorMessage, setErrorMessage] = useState('');
const [isActionDialogOpen, setIsActionDialogOpen] = useState(false);
const [selectedAction, setSelectedAction] = useState('');
const [isLoading, setIsLoading] = useState(false);
const [showNewPassword, setShowNewPassword] = useState(false);
const [showConfirmPassword, setShowConfirmPassword] = useState(false);
const [dialogTitle, setDialogTitle] = useState("Add New Group");
const [fullHierarchy, setFullHierarchy] = useState("");


const showDataTab =  true;
const showSurveyTab = true;


const [modalPollOpen, setModalPollOpen] = useState(false);
  const [pollingBoothData, setPollingBoothData] = useState({
    polling_booth_name: '',
    polling_booth_number: ''
  });

  const handleOpenPollModals = () => {
    
    setPollingBoothData({
      polling_booth_name: '',
      polling_booth_number: ''
    });
    setModalPollOpen(true);
  };

  const handleClosePollModals = () => {
    setModalPollOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPollingBoothData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    if (!pollingBoothData.polling_booth_name || !pollingBoothData.polling_booth_number) {
      alert('Please fill in all required fields.');
      return;
    }

    handleClosePollModals();
    await handleFileUpload();
  };

  const handleFileUpload = async () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.xlsx,.xls,.csv';

    input.onchange = async (event) => {
      const file = event.target.files[0];
      if (!file) {
        console.error('No file selected');
        return;
      }

      const formData = new FormData();
      formData.append('file', file);
      formData.append('group_id', selectedGroupId);
      formData.append('polling_booth_name', pollingBoothData.polling_booth_name);
      formData.append('polling_booth_number', pollingBoothData.polling_booth_number);

      try {
        const response = await axios.post(`${APIURL}/data_collection/api/upload-individuals/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Token ${token}`
          }
        });

        if (response.data.status === 'success') {
          console.log(response.data.message);
          alert(response.data.message);
          fetchIndividuals(selectedGroupId);
        } else {
          console.error('Upload failed:', response.data.message);
          alert(`Upload failed: ${response.data.message}`);
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        alert('Error uploading file. Please try again.');
      }
    };

    input.click();
  };


const formatDate = (date) => {
  const [year, month, day] = date.split('-');
  return `${day}-${month}-${year}`;
};

const displayDate = (date) => {
  const [day, month, year] = date.split('-');
  return `${year}-${month}-${day}`;
};


const handleEditGroup = async (selectedGroupId) => {
  console.log("Selected Group ID for editing:", selectedGroupId);
  if (!selectedGroupId) {
    alert("No group selected for editing.");
    return;
  }

  const newName = prompt("Enter new group name:");
  if (!newName) {
    alert("Edit cancelled or no name provided.");
    return;
  }

  try {
    const response = await fetch(`${APIURL}/backend/api/group/?group_id=${selectedGroupId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`,
      },
      body: JSON.stringify({
        group_id: selectedGroupId,
        name: newName,
      }),
    });

    const data = await response.json();
    console.log("Edit group response:", data);

    if (data.status === 'success') {
      alert(data.message);
      window.location.reload();
    } else {
      alert(`Error: ${data.message}`);
    }
  } catch (error) {
    console.error('Error editing group:', error);
    alert('An error occurred while editing the group.');
  }
};


const handleDeleteGroup = async (selectedGroupId) => {
  if (!selectedGroupId) {
    alert('No group selected for deletion.');
    return;
  }

  const confirmDelete = window.confirm('Are you sure you want to delete this group?');

  if (confirmDelete) {
    try {
      const response = await fetch(`${APIURL}/backend/api/group/?group_id=${selectedGroupId}&confirm=true`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        },
      });

      const data = await response.json();

      if (data.status === 'success') {
        alert(data.message);

        const parent = findParentNode(treeData, selectedGroupId);
        if (parent) {
          handleTeamMemberClick(parent.group_id, parent.group_type, parent.group_name, parent.group_type_name, parent.hierarchy_full);
        } else {
          setSelectedGroup(null);
          setSelectedGroupId(null);
          setSelectedGroupType(null);
          setSelectedGroupTypeName(null);
          setChildGroupTypeName(null);
          setFullHierarchy(null);
          await fetchUpdatedData();
        }
        await fetchUpdatedData(); 
      } else {
        alert(`Error: ${data.message}`);
      }
    } catch (error) {
      console.error('Error deleting group:', error);
      alert('An error occurred while deleting the group.');
    }
  }
};




  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const capitalizeFirstLetter = (string) => {

    return string.charAt(0).toUpperCase() + string.slice(1);
  
  };




const addRow = async () => {
  const mandatoryFields = ['name', 'gender','house_no','house_name','polling_booth_number','polling_booth_name'];
  const emptyMandatoryFields = mandatoryFields.filter(field => !newContact[field]);

  if (emptyMandatoryFields.length > 0) {
    alert(`Please fill all required fields: ${emptyMandatoryFields.join(', ')}`);
    return;
  }


  if (!Number.isInteger(Number(newContact.sl_no))) {
    alert('Serial number must be an integer.');
    return;
  }

  if (!Number.isInteger(Number(newContact.house_no))) {
    alert('House number must be an integer.');
    return;
  }

  const dataToSend = { 
    ...newContact,
    dob: formatDate(newContact.dob),
    eligible_to_vote: Boolean(newContact.eligible_to_vote),
  };
  delete dataToSend.age;

  try {
    const response = await fetch(`${APIURL}/data_collection/api/add-individuals/?group_id=${newContact.group}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
      body: JSON.stringify(dataToSend) 
    });

    const data = await response.json();
    
    if (data.status === 'success') {
      alert(data.message || 'New data added');

   // Update the local contacts state with the new contact
   setContacts(prevContacts => {
    if (Array.isArray(prevContacts)) {
      return [...prevContacts, newContact];
    } else {
     
      return [newContact];
    }
  });

      // Reset the form
      setNewContact({
        group: selectedGroupId,
        polling_booth_number: '',
    polling_booth_name: '',
    sl_no: '',
    house_no: '',
    house_name: '',
    name: '',
    guardian_name: '',
    dob: '',
    age: '',
    gender: 'choose an option',
    epic_no: '',
    eligible_to_vote:false
      });
      setModalOpen(false);
    } else {
      alert(data.message || 'Failed to add new data');
    }
  } catch (error) {
    console.error('Error adding new contact:', error);
    alert('An error occurred while adding the contact');
  }
};


const fetchIndividuals = async (groupId) => {
   try {
    const response = await fetch(`${APIURL}/data_collection/api/individuals-raw/?group_id=${groupId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${token}`
      },
    });
    const data = await response.json();
    if (data.status === "success") {
      setContacts(data.data || []);
    } else {
      setContacts([]);
    }
  } catch (error) {
    console.error("Error fetching individuals:", error);
    setContacts([]);
  }
};


// const editRow = (index) => {
//   setCurrentContact({ ...contacts[index], index });
//   setEditModalOpen(true);
// };

// const updateRow = () => {
//   const mandatoryFields = ['sl_no', 'name', 'gender', 'dob','epic_no'];
//   const emptyMandatoryFields = mandatoryFields.filter(field => !currentContact[field]);

//   if (emptyMandatoryFields.length > 0) {
//     alert(`Please fill all required fields: ${emptyMandatoryFields.join(', ')}`);
//     return;
//   }
//   if (newContact.phone.length !== 10) {
//     setErrorMessage('Phone number must be 10 digits long');
//     return;
//   }

//   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//   if (!emailRegex.test(newContact.email)) {
//     setErrorMessage('Please enter a valid email address');
//     return;
//   }

//   const updatedContacts = contacts.map((contact, index) =>
//     index === currentContact.index ? {...currentContact} : contact
//   );

//   setContacts(updatedContacts);
//   setCurrentContact(null);
//   setEditModalOpen(false);
  
// };





// const handleChange = (e, setContact) => {
//   const { name, value } = e.target;
//   const formattedValue = name === 'dob' ? formatDate(value) : value;
//   setContact(prevState => ({ ...prevState, [name]: formattedValue }));
// };


  const handleChange = (e, setter) => {
    const { name, value } = e.target;
    setter(prev => {
      const updatedContact = { ...prev, [name]: value };
      
      if (name === 'age') {
        updatedContact.dob = calculateDOBFromAge(parseInt(value));
      } else if (name === 'dob') {
        updatedContact.age = calculateAgeFromDOB(value);
      }
      
      return updatedContact;
    });
  };
  



const deleteRow = (index) => {
  if (window.confirm('Are you sure you want to delete this contact?')) {
    const updatedContacts = contacts.filter((_, i) => i !== index);
    setContacts(updatedContacts);
    
  }
};

// const updateRow = async () => {
//   const mandatoryFields = ['sl_no', 'name', 'gender', 'dob', 'family_name', 'guardian_name', 'epic_no'];
//   if (mandatoryFields.some(field => !currentContact[field])) {
//     alert(`Please fill all required fields: ${mandatoryFields.join(', ')}`);
//     return;
//   }

// if (!Number.isInteger(Number(currentContact.sl_no))) {
//   alert('Serial number must be an integer.');
//   return;
// }

// if (!Number.isInteger(Number(currentContact.house_no))) {
//   alert('House number must be an integer.');
//   return;
// }

// const updatedContact = {
//   ...currentContact,
//   sl_no: parseInt(currentContact.sl_no, 10),
//   house_no: parseInt(currentContact.house_no, 10),
// };

//   try {
//     const response = await fetch(`${APIURL}/data_collection/api/individuals/edit/`, {
//       method: 'PUT',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Token ${token}`
//       }
//     });

//     const data = await response.json();

//     if (data.status === 'success') {
//       setContacts(contacts.map(contact => 
//         contact.id === currentContact.id ? currentContact : contact
//       ));
//       setEditModalOpen(false);
//       alert(data.message);
//     } else {
//       alert(data.message || 'Update failed');
//     }
//   } catch (error) {
//     console.error('Update error:', error);
//     alert('Update failed');
//   }
// };

// const deleteRow = async (index) => {
//   if (!window.confirm('Are you sure you want to delete this data?')) return;

//   try {
//     const response = await fetch(`${APIURL}/data_collection/api/individuals/delete/`, {
//       method: 'PUT',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Token ${token}`
//       }
//     });

//     const data = await response.json();

//     if (data.status === 'success') {
//       setContacts(contacts.filter((_, i) => i !== index));
//       alert(data.message);
//     } else {
//       alert(data.message || 'Deletion failed');
//     }
//   } catch (error) {
//     console.error('Deletion error:', error);
//     alert('Deletion failed');
//   }
// };
const toggleLeftSidebar = () => {
      setLeftSidebarOpen(!leftSidebarOpen);
    };


    

    const handleTabChange = (event, newIndex) => {
      setTabIndex(newIndex);
      setTabIndexs(false);
      
    };

    const handleTabChanges = (event, newIndexs) => {
      setTabIndexs(newIndexs);
      setTabIndex(false);
      
    };

  

    useEffect(() => {
      const token = localStorage.getItem('codeDcUserToken');
      if (!token) {
        console.log("Token not found, redirecting...");
        navigate('/login');
      } else {
        const fetchData = async () => {
          try {
            const response = await fetch(`${APIURL}/backend/api/sub-group-tree/`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
              },
            });
            const responseJson = await response.json();
            const data = responseJson.data;
            const keralaData = data[0];
            setFullHierarchy(keralaData.hierarchy_full);
            setIsLastLevel(keralaData.group_type === 6);
    
            const updatedTreeData = addNewNode([keralaData]);
            setTreeData(updatedTreeData);
    
            setSelectedGroup(keralaData.group_name);
            setSelectedGroupId(keralaData.group_id);
            setSelectedGroupType(keralaData.group_type);
            setSelectedGroupTypeName(keralaData.group_type_name);
            setChildGroupTypeName(keralaData.child_group_type_name);
            setTeamMembers([...keralaData.children, {
              id: `add-${keralaData.group_id}`,
              group_type: 0,
              name: "Add New",
              group_name: "Add New",
              isNew: true,
              parent: keralaData.group_id,
              parent_type: keralaData.group_type
            }]);
          } catch (error) {
            console.error("Error fetching the tree data:", error);
          }
        };
        fetchData();
      }
    }, []);

    useEffect(() => {
      if (selectedGroupId) {
        const fetchGroupMembers = async () => {
          try {
            const response = await fetch(`${APIURL}/backend/api/group-members/?group_id=${selectedGroupId}`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
              },
            });
            const responseJson = await response.json();
            const data = responseJson.data;
            
            // Map the API response to include the status
            const updatedUsers = data.map(user => ({
              ...user,
              status: user.user_status === 1 ? 'active' : 'suspended'
            }));
            
            setUsers(updatedUsers);
          } catch (error) {
            console.error("Error fetching the group members:", error);
          }
        };

        fetchGroupMembers();
      }
    }, [selectedGroupId, token]);

    useEffect(() => {
      if (selectedGroupId) {
        fetchIndividuals(selectedGroupId);
      }
    }, [selectedGroupId]);

    const addNewNode = (nodes, parentId = null) => {
      return nodes.map(node => ({
        ...node,
        children: node.children
          ? [...addNewNode(node.children), 
            node.group_type < 6
                ? { id: `add-${node.id}`, group_type: 0, name: "Add New", group_name: "Add New", isNew: true, parent: node.group_id, parent_type: node.group_type } 
                : null
            ].filter(Boolean) 
          : node.group_type < 6
              ? [{ id: `add-${node.id}`, group_type: node.group_type, name: "Add New", group_name: "Add New", isNew: true, parent: node.group_id }] 
              : []
      }));
    };
    useEffect(() => {
      setNewContact(prevContact => ({
        ...prevContact,
        group: selectedGroupId
      }));
    }, [selectedGroupId]);
    useEffect(() => {
      console.log("Selected Group ID:", selectedGroupId);
      console.log("Contacts:", contacts);
    }, [selectedGroupId, contacts]);


    const handleNodeSelect = (node) => {
      console.log("nav click");    
    
      if (node.teamMembers) {
        setTeamMembers(node.teamMembers);
        console.log("node.teamMembers");
        console.log(node.teamMembers);
      } else {
        console.log("node.children:", node);
        setSelectedGroup(node.group_name);
        setSelectedGroupId(node.group_id);
        setSelectedGroupType(node.group_type);
        setSelectedGroupTypeName(node.group_type_name);
        setChildGroupTypeName(node.child_group_type_name);
        setFullHierarchy(node.hierarchy_full);

        setChildGroupTypeName(node.child_group_type_name || getChildGroupTypeName(node.group_type));
        
        if (Array.isArray(node.children)) {


        var folderData = [...node.children];  // Create a new array from node.children
          folderData.unshift({
          group_id: node.group_id,
          group_type: 1,
          name: "Back",
          group_name: "Back",
          parent: node.group_id,
          isBack: true
        });

        console.log(folderData);

        console.log(node.children);
        
        setTeamMembers(folderData);
          // setTeamMembers(node.children);
        } else {
          setTeamMembers([]);
        }
      }
      
      
      // Set isLastLevel based on group_type
      setIsLastLevel(node.group_type === 6);
      
      // Fetch individuals for the selected group
      fetchIndividuals(node.group_id);
    };

    
   
    const handleToggle = (event, nodeIds) => {
      setExpanded(nodeIds); // Update expanded state here
    };

    const handleAddNewClick = (parentId, type) => {
      setSelectedParentId(parentId);
      setGroupType(type);
      setIsPopupOpenNew(true);

       // Set the dialog title based on the group type
   const groupLevelNames = getChildGroupTypeNames(type);
   setDialogTitle(`Add New ${groupLevelNames}`);

      // Add the new node to the tree data
      const newNode = {
          id: `add-${parentId}`, // Use a unique identifier for the new node
          group_type: 0,
          name: "Add New",
          group_name: "Add New",
          isNew: true,
          parent: parentId,
          parent_type: type
      };

      // Find the parent node in the tree data
      const updatedTreeData = treeData.map(node => {

        if(storedUserRole != 0){
          if (node.id === parentId) {
            // Append the new node to the parent's children
            return {
                ...node,
                children: node.children ? [...node.children, newNode] : [newNode]
            };
          }
        }

        return node;
    });

    // Update the tree data state with the new node
    setTreeData(updatedTreeData);
    };

    const handleAdd = async () => {
      const newGroup = {
        name: newEntry,
        group_type: groupType + 1,
        parent: selectedParentId,
      };
    
      try {
        const response = await fetch(`${APIURL}/backend/api/group/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
          },
          body: JSON.stringify(newGroup),
        });
    
        if (response.ok) {
          const responseJson = await response.json();
          const data = responseJson.data;
          console.log(responseJson);
          setResponseMessage(responseJson.message);
          console.log('New group added:', data);
    
          
    
          // Fetch updated data
          await fetchUpdatedData();
        } else {
          console.log(response);
          setResponseMessage('Failed to add new group: ' + response.statusText);
        }
      } catch (error) {
        setResponseMessage('Error adding new group: ' + error.message);
      }
    };
    
    // Function to fetch updated data
    const fetchUpdatedData = async () => {
      setIsLoading(true);
      try {
        // Fetch updated tree data
        const treeResponse = await fetch(`${APIURL}/backend/api/sub-group-tree/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Token ${token}`
          },
        });
        const treeResponseJson = await treeResponse.json();
        const treeData = treeResponseJson.data;
        const keralaData = treeData[0];

        if(storedUserRole != 0){
          const updatedTreeData = addNewNode([keralaData]);
          setTreeData(updatedTreeData);  
        }else{
          setTreeData(keralaData);  
        }

    
        // Fetch updated team members
        const membersResponse = await fetch(`${APIURL}/backend/api/group-children/?group_id=${selectedGroupId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Token ${token}`
          },
        });
        const membersResponseJson = await membersResponse.json();
        let membersData = membersResponseJson.data;
    
        // Add the "Add New" option if the current group type is less than 6
        if (selectedGroupType < 6 && storedUserRole != 0) {        
          membersData.push({
            group_id: `add-${selectedGroupId}`,
            group_type: 0,
            group_name: "Add New",
            isNew: true,
            parent: selectedGroupId,
            parent_type: selectedGroupType
          });
        }
    
        setTeamMembers(membersData);
    
        console.log("Data updated successfully");
      } catch (error) {
        console.error("Error fetching updated data:", error);
      } finally {
        setIsLoading(false);
      }
    };


    const handleNameChange = (event) => {
        setNewEntry(event.target.value);
    };

    const handlePopupClose = () => {
        setIsPopupOpenNew(false);
        setSelectedParentId(null);
        setNewEntry(''); // Reset name input
        setResponseMessage(''); // Reset response message
    };



    const handleAddUserInputChange = (event) => {
      const { name, value } = event.target;
      setNewUser((prevUser) => ({
        ...prevUser,
        [name]: name === 'role' ? parseInt(value, 10) : value,
        ...(name === 'passwordadmin' && { confirm_password: value }),
        group_id: selectedGroupId
      }));
    };

    const handleAddUser = async () => {
      const url = `${APIURL}/backend/api/add-user/`;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify({
          ...newUser,
          username: newUser.usernameadmin,  
          password: newUser.passwordadmin, 
          group_id: selectedGroupId 
        })
      };
    
      try {
        const response = await fetch(url, options);
        const data = await response.json();
        console.log(data);
    
        if (response.ok) {
          if(data.status == "error"){
            setAddUserError(data.message);
          } else {
            // Store the new user's password temporarily
            sessionStorage.setItem('newUserPassword', newUser.passwordadmin);
    
            // Add the new user to the users list
            if (selectedGroupId) {
              const fetchGroupMembers = async () => {
                try {
                  const response = await fetch(`${APIURL}/backend/api/group-members/?group_id=${selectedGroupId}`, {
                    method: "GET",
                    headers: {
                      "Content-Type": "application/json",
                      "Authorization": `Token ${token}`
                    },
                  });
                  const responseJson = await response.json();
                  const data = responseJson.data;
                  console.log(data);
                  setUsers(data); // Assuming the response contains an array of members
                } catch (error) {
                  console.error("Error fetching the group members:", error);
                }
              };
              fetchGroupMembers();
            }
            setNewUser({
              usernameadmin: '',
              passwordadmin: '',
              confirm_password: '',
              first_name: '',
              last_name: '',
              email: '',
              mobile: '',
              group_id: selectedGroupId,
              role: 0
            });
            setIsAddUserPopupOpen(false);
          }
        } else {
          console.error('Failed to add user:', data);
        }
      } catch (error) {
        console.error('Error adding user:', error);
      }
    };

    const handleMoreVertIconClick = (user) => {
      setSelectedUser(user);
      setIsUserDetailsPopupOpen(true);
    };

    const handleUserDetailsPopupClose = () => {
      setIsUserDetailsPopupOpen(false);
      setSelectedUser(null);
    };
    

    const handleProfileEditOpen = () => {
      setIsProfileEditOpen(true);
      // Populate profile fields with existing data including role
      console.log(selectedUser);
      setProfileFields({
        user_id: selectedUser.id,
        username: selectedUser.username,  
        first_name: selectedUser.first_name,
        last_name: selectedUser.last_name,
        dob: selectedUser.dob,
        gender: selectedUser.gender,
        email: selectedUser.email,
        phone: selectedUser.mobile,
        houseName: selectedUser.houseName,
        role: selectedUser.role  // Include role here
      });
    };
    

    const calculateDOBFromAge = (age) => {
      const currentDate = new Date();
      const year = currentDate.getFullYear() - age;
      return `${year}-01-01`;
    };
    const calculateAgeFromDOB = (dob) => {
      const birthDate = new Date(dob);
      const currentDate = new Date();
      let age = currentDate.getFullYear() - birthDate.getFullYear();
      const monthDiff = currentDate.getMonth() - birthDate.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && currentDate.getDate() < birthDate.getDate())) {
        age--;
      }
      return age.toString();
    };


    

    // Function to handle closing profile edit pop-up
const handleProfileEditClose = () => {
  setIsProfileEditOpen(false);
  // Clear profile field values
  setProfileFields({
    user_id: '',
    first_name: '',
    last_name: '',
    dob: '',
    gender: 'choose an option',
    email: '',
    phone: '',
    houseName: '',
    role: ''  // Reset role here
  });
};


    const customStyles = {
      table: {
        style: {
          width: '99%',
          borderCollapse: 'collapse',
          minWidth: '600px',
          backgroundColor: '#E4DD9F85',
          borderRadius: '50px',
          marginLeft:'5px'
        },
      },
      rows: {
        style: {
          minHeight: '50px', // Reduced from 72px
          backgroundColor: '#E4DD9F85',
          
          
        },
      },
      headCells: {
        style: {
          paddingLeft: '4px', // Reduced from 8px
          paddingRight: '4px', // Reduced from 8px
          backgroundColor: '#C8AE81',
          border: '2px solid #FFFFFF',
          color: 'black',
          fontWeight: 'bold',
        },
      },
      cells: {
        style: {
          paddingLeft: '4px', // Reduced from 8px
          paddingRight: '4px', // Reduced from 8px
          border: '2px solid #FFFFFF', // Reduced from 4px
          padding: '8px', // Reduced from 16px
          backgroundColor: '#E4DD9F85',
          color:'#000000'
        },
      },
    };

    
    const handleSave = async () => {
      const updatedUser = {
        user_id: profileFields.user_id,
        username: profileFields.username,
        first_name: profileFields.first_name,
        last_name: profileFields.last_name,
        dob: profileFields.dob,
        gender: profileFields.gender,
        email: profileFields.email,
        mobile: profileFields.phone,
        houseName: profileFields.houseName,
        role: profileFields.role
      };
    
      console.log(updatedUser);
    
      try {
        const response = await fetch(`${APIURL}/backend/api/edit-user/?user_id=${profileFields.user_id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
          },
          body: JSON.stringify(updatedUser)
        });
    
        const data = await response.json();
    
        if (data.status === 'success') {
          alert(data.message);
          console.log('User profile updated:', data);
    
          // Fetch updated group member data
          if (selectedGroupId) {
            const fetchGroupMembers = async () => {
              try {
                const response = await fetch(`${APIURL}/backend/api/group-members/?group_id=${selectedGroupId}`, {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`
                  },
                });
                const responseJson = await response.json();
                const data = responseJson.data;
                console.log(data);
                setUsers(data);
              } catch (error) {
                console.error("Error fetching the group members:", error);
              }
            };
            fetchGroupMembers();
          }
    
          
          setIsProfileEditOpen(false);
        } else {
          
          alert(data.message);
          console.error('Failed to update user profile:', data);
        }
      } catch (error) {
        
        alert(`Error: ${error.message || 'An unexpected error occurred'}`);
        console.error('Error updating user profile:', error);
      }
    };
    


    const handleUserClick = (user) => {
      setSelectedUser(user);
      setIsUserDetailsPopupOpen(true);
    };



   
    // const handleAction = async () => {
    //   if (!selectedUser || !selectedAction) {
    //     alert("Please select a user and an action.");
    //     return;
    //   }
    
    //   if (!window.confirm(`Are you sure you want to this user?`)) {
    //     return;
    //   }
    
    //   try {
    //     // Create the request body
    //     const requestBody = {
    //       action: selectedAction
    //     };
    
    //     // Add either user_id or username to the request body
    //     if (selectedUser.id) {
    //       requestBody.user_id = selectedUser.id;
    //     } else if (selectedUser.username) {
    //       requestBody.username = selectedUser.username;
    //     } else {
    //       alert("Unable to identify the selected user.");
    //       return;
    //     }
    
    //     const response = await fetch(`${APIURL}/backend/api/action/?user_id=${selectedUser.id}`, {
    //       method: 'PUT',
    //       headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Token ${token}`
    //       },
    //       body: JSON.stringify(requestBody)
    //     });
    
    //     const data = await response.json();
    
    //     if (data.status === 'success') {
          
    //       console.log('Action performed:', data.message);
    //       if (selectedAction === 'remove') {
    //         setUsers(users.filter(user => user.id !== selectedUser.id));
    //       } 
    //       setIsUserDetailsPopupOpen(false);
    //       setIsActionDialogOpen(false);
    //       setSelectedUser(null);
    //       setSelectedAction('');
    //       alert(data.message);
    //       setIsActionDialogOpen(false);
    //     } else {
    //       console.error('Failed to perform action:', data.message);
    //       alert(`Failed to ${selectedAction} user. ${data.message}`);
    //     }
    //     setIsActionDialogOpen(false);
    //   } catch (error) {
    //     console.error('Error performing action:', error);
    //     alert(`An error occurred while performing the action: ${error.message}`);
    //   }
    //   setIsActionDialogOpen(false);
    // };


    const handleAction = async () => {
      if (!selectedUser || !selectedAction) {
        alert("Please select a user and an action.");
        return;
      }
    
      if (!window.confirm(`Are you sure you want to ${selectedAction} this user?`)) {
        return;
      }
    
      try {
        const requestBody = {
          action: selectedAction,
          user_id: selectedUser.id
        };
    
        const response = await fetch(`${APIURL}/backend/api/action/?user_id=${selectedUser.id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
          },
          body: JSON.stringify(requestBody)
        });
    
        const data = await response.json();
    
        if (data.status === 'success') {
          console.log('Action performed:', data.message);
          
          // Update the user's status in the local state
          setUsers(users.map(user => 
            user.id === selectedUser.id 
              ? { ...user, status: selectedAction === 'suspend' ? 'suspended' : selectedAction === 'resume' ? 'active' : user.status }
              : user
          ));
    
          if (selectedAction === 'remove') {
            setUsers(users.filter(user => user.id !== selectedUser.id));
          }
    
          setIsUserDetailsPopupOpen(false);
          setIsActionDialogOpen(false);
          setSelectedUser(null);
          setSelectedAction('');
          alert(data.message); 
        } else {
          console.error('Failed to perform action:', data.message);
          alert(`Failed to ${selectedAction} user. ${data.message}`);
        }
      } catch (error) {
        console.error('Error performing action:', error);
        alert(`An error occurred while performing the action: ${error.message}`);
      }
      setIsActionDialogOpen(false);
    };



    const handleCancel = () => {
      setIsProfileEditOpen(false);
    };


// Function to handle changes in profile fields
const handleProfileFieldChange = (event) => {
  const { name, value } = event.target;
  setProfileFields((prevFields) => ({
    ...prevFields,
    [name]: name === 'role' ? parseInt(value, 10) : value  // Ensure role is parsed as an integer
  }));
};
const profileEditPopup = (
  <Dialog open={isProfileEditOpen} onClose={handleProfileEditClose}>
    <div className="dialog-header">
      <DialogTitle className="dialog-title">Edit Profile</DialogTitle>
    </div>
    <DialogContent className="inputbox">
    <div className="form-row">
        <div className="form-field-container">
          <Typography variant="body1" className="field-label">Username</Typography>
          <TextField
            name="username"
            type="text"
            fullWidth
            variant="outlined"
            value={profileFields.username}
            onChange={handleProfileFieldChange}
            className="form-field"
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-field-container">
          <Typography variant="body1" className="field-label">First Name</Typography>
          <TextField
            name="first_name"
            type="text"
            fullWidth
            variant="outlined"
            value={profileFields.first_name}
            onChange={handleProfileFieldChange}
            className="form-field"
          />
        </div>
        <div className="form-field-container">
          <Typography variant="body1" className="field-label">Last Name</Typography>
          <TextField
            name="last_name"
            type="text"
            fullWidth
            variant="outlined"
            value={profileFields.last_name}
            onChange={handleProfileFieldChange}
            className="form-field"
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-field-container">
          <Typography variant="body1" className="field-label">Email</Typography>
          <TextField
            name="email"
            type="email"
            fullWidth
            variant="outlined"
            value={profileFields.email}
            onChange={handleProfileFieldChange}
            className="form-field"
            inputProps={{
              pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$"
            }}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-field-container">
          <Typography variant="body1" className="field-label">Mobile</Typography>
          <TextField
            name="phone"
            type="tel"
            fullWidth
            variant="outlined"
            value={profileFields.phone}
            onChange={handleProfileFieldChange}
            className="form-field"
            InputProps={{
              startAdornment: <InputAdornment position="start">91</InputAdornment>,
            }}
            inputProps={{
              maxLength: 10,
            }}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-field-container">
          <Typography variant="body1" className="field-label">User Type</Typography>
          <div className="box">
            <div className="radio-buttons">
              <label className="radio-label">
                <input
                  type="radio"
                  name="role"
                  value="2"
                  checked={profileFields.role === 2}
                  onChange={handleProfileFieldChange}
                />
                Admin
              </label>
              <label className="radio-label">
                <input
                  type="radio"
                  name="role"
                  value="0"
                  checked={profileFields.role === 0}
                  onChange={handleProfileFieldChange}
                />
                Viewer
              </label>
              <label className="radio-label">
                <input
                  type="radio"
                  name="role"
                  value="1"
                  checked={profileFields.role === 1}
                  onChange={handleProfileFieldChange}
                />
                Editor
              </label>
            </div>
          </div>
        </div>
      </div>
    </DialogContent>
    <DialogActions className="dialog-actions">
      <Button onClick={handleSave} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '100px', marginRight: '10px' }}>
        Save
      </Button>
      <Button onClick={handleCancel} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '100px' }}>
        Cancel
      </Button>
    </DialogActions>
  </Dialog>
);

const getChildGroupTypeName = (groupType) => {
  const groupTypes = [
    "State","District", ,"Area", "local", "Ward", "cluster"
  ];
  return groupTypes[groupType] ;
};

const getChildGroupTypeNames = (groupType) => {
  const groupTypes = [
    "State","District", ,"Area", "local", "Ward", "cluster"
  ];
  return groupTypes[groupType] || "District"  ;
};

const findParentNode = (nodes, childId) => {
  for (const node of nodes) {
    if (node.children && node.children.some(child => child.group_id === childId)) {
      return node; // Found the parent
    }
    if (node.children) {
      const parent = findParentNode(node.children, childId);
      if (parent) return parent;
    }
  }
  return null;
};

const handleTeamMemberClick = async (memberId, group_type, group_name, group_type_name, hierarchy_full) => {
  console.log("memberId: ", memberId);
  console.log("group_type: ", group_type);
  if (group_type === 0) {
    handleAddNewClick(selectedGroupId, selectedGroupType);
  }
  else if (group_type === 1) {
    console.log("Back clicked");
    // Find the parent node
    const parent = findParentNode(treeData, memberId);
    if (parent) {
      console.log("found");
      setSelectedGroup(parent.group_name);
      setSelectedGroupId(parent.group_id);
      setSelectedGroupType(parent.group_type);
      setSelectedGroupTypeName(parent.group_type_name);
      setChildGroupTypeName(parent.child_group_type_name || getChildGroupTypeName(parent.group_type));
      setFullHierarchy(parent.hierarchy_full);

      var folderData = [...parent.children];  // Create a new array from node.children
      folderData.unshift({
        group_id: parent.group_id,
        group_type: 1,
        name: "Back",
        group_name: "Back",
        parent: parent.group_id,
        isBack: true
      });

      setTeamMembers(folderData);
      fetchIndividuals(parent.group_id);
      setIsLastLevel(false); 
    } else {
      console.log(" not found");
    }
  } else {
    try {
      const response = await fetch(`${APIURL}/backend/api/group-children/?group_id=${memberId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Token ${token}`
        },
      });
      const responseJson = await response.json();
      const data = responseJson.data;
      console.log(data);    

      data.unshift({
        group_id: memberId,
        group_type: 1,
        name: "Back",
        group_name: "Back",
        parent: memberId,
        isBack: true
      });

      if (group_type < 6 && storedUserRole != 0) {
        data.push({
          group_id: `add-${memberId}`,
          group_type: 0,
          name: "Add New",
          group_name: "Add New",
          isNew: true,
          parent: memberId,
          parent_type: group_type
        });
      }

      setTeamMembers(data);
      setSelectedGroup(group_name);
      setSelectedGroupType(group_type);
      setSelectedGroupTypeName(group_type_name);
      setSelectedGroupId(memberId);
      setChildGroupTypeName(getChildGroupTypeName(group_type));
      setFullHierarchy(hierarchy_full);

      setIsLastLevel(group_type === 6);
      
      fetchIndividuals(memberId);
    } catch (error) {
      console.error("Error fetching the child members:", error);
    }
  }
};


    const handleSetPasswordOpen = () => {
      setIsSetPasswordPopupOpen(true);
    };
    
    const handleSetPasswordClose = () => {
      setIsSetPasswordPopupOpen(false);
      setNewPasswordNew('');
      setConfirmPasswordConfirm('');
    };
    
    const handleNewPasswordChange = (event) => {
      setNewPasswordNew(event.target.value);
    };
    
    const handleConfirmPasswordChange = (event) => {
      setConfirmPasswordConfirm(event.target.value);
    };
    

    // const handleSavePassword = async () => {
      // if (!currentPassword) {
      //   setAddUserError("Current password is required");
      //   return;
      // }
      
      // if (newPassword !== confirmPassword) {
      //   setAddUserError("New password and confirm password don't match");
      //   return;
      // }
    
      // if (!selectedUser || !selectedUser.id) {
      //   setAddUserError("No user selected");
      //   return;
      // }
    
      // const updatedPasswordData = {
      //   user_id: selectedUser.id,
      //   current_password: currentPassword,
      //   new_password: newPassword,
      //   confirm_password: confirmPassword
      // };
    
      // try {
      //   const response = await fetch(`${APIURL}/backend/api/reset-password/`, {
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json',
      //       'Authorization': `Token ${token}`
      //     },
      //     body: JSON.stringify(updatedPasswordData)
      //   });
    
      //   const data = await response.json();
    
      //   if (response.ok) {
      //     console.log('Password updated:', data);
      //     setIsSetPasswordPopupOpen(false);
      //     setCurrentPassword('');
      //     setNewPassword('');
      //     setConfirmPassword('');
      //     alert('Password updated successfully');
      //   } else {
      //     setAddUserError(data.message || 'Failed to update password');
      //   }
      // } catch (error) {
      //   console.error('Error updating password:', error);
      //   setAddUserError('An error occurred while updating the password');
      // }
   // };


   const handleSavePassword = async () => {
    if (newpasswordnew !== confirmpasswordconfirm) {
      setAddUserError("New password and confirm password don't match");
      return;
    }
  
    if (!selectedUser || !selectedUser.id) {
      setAddUserError("No user selected");
      return;
    }
  
    const updatedPasswordData = {
      new_password: newpasswordnew,
      confirm_password: confirmpasswordconfirm
    };
  
    try {
      const response = await fetch(`${APIURL}/backend/api/user-password-reset/?user_id=${selectedUser.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(updatedPasswordData)
      });
  
      const data = await response.json();
  
      if (data.status === 'success') {
        console.log('Password updated:', data.message);
        setIsSetPasswordPopupOpen(false);
        setNewPasswordNew('');
        setConfirmPasswordConfirm('');
        alert(data.message);
      } else {
        setAddUserError(data.message || 'Failed to update password');
      }
    } catch (error) {
      console.error('Error updating password:', error);
      setAddUserError('An error occurred while updating the password');
    }
  };


    const setPasswordPopup = (
      <Dialog open={isSetPasswordPopupOpen} onClose={handleSetPasswordClose}>
      <div className="dialog-header">
        <DialogTitle className="dialog-title">Reset Password</DialogTitle>
        <CloseIcon className="pop-close-icon" onClick={handleSetPasswordClose} />
      </div>
      <DialogContent className="inputbox">
        <div className="form-field-container">
          <Typography variant="body1" className="field-label">New Password</Typography>
          <TextField
             name="newpasswordnew"
            type={showNewPassword ? "text" : "password"}
            fullWidth
            variant="outlined"
            value={newpasswordnew}
  onChange={handleNewPasswordChange}
            className="form-field"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggleNewPasswordVisibility}
                    edge="end"
                  >
                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="form-field-container">
          <Typography variant="body1" className="field-label">Confirm Password</Typography>
          <TextField
            name="confirmpasswordconfirm"
            type={showConfirmPassword ? "text" : "password"}
            fullWidth
            variant="outlined"
            value={confirmpasswordconfirm}
  onChange={handleConfirmPasswordChange}
            className="form-field"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggleConfirmPasswordVisibility}
                    edge="end"
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        {addUserError && (
          <Typography color="error" variant="body2" className="error-message">
            {addUserError}
          </Typography>
        )}
      </DialogContent>
      <DialogActions className="dialog-actions">
        <Button onClick={handleSavePassword} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '100px',height :'30px' ,marginRight: '10px' }}>
          Save
        </Button>
        <Button onClick={handleSetPasswordClose} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '100px',height :'30px' }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
);
        <style jsx>{`
          .dialog-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .dialog-title {
            text-align: center;
            flex-grow: 1;
            color: #035391;
            font-weight: 500;
            margin-right: 90px;
          }
          .pop-close-icon {
            cursor: pointer;
            margin-right: 15px;
          }
          .inputbox {
            padding: 16px;
          }
          .dialog-actions {
            justify-content: center;
            background-color: white;
            border-radius: 45px;
            width: 220px; /* Adjust width as needed */
            margin: 0 auto; /* Center horizontally */
            margin-bottom: 20px;
          }
          .form-field-container {
            margin-bottom: 20px;
          }
          .field-label {
            margin-bottom: 8px;
          }
          .form-field {
            width: 100%;
          }
        `}</style>
     ;

     const handleLogout = () => {
      
      const confirmLogout = window.confirm("Are you sure you want to Logout?");
      
      if (confirmLogout) {
    
        localStorage.removeItem('userToken');
        localStorage.removeItem('codeDcUserToken');        
        window.location.href = '/';
      }
    
    };


 


    const LogoutButton = styled.div`
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      margin-right: 10px;
      @media (max-width: 1000px) {
          width: 20px;
          height: 20px;
      }
    `;

    useEffect(() => {
      if (tabIndex === 1 && isLastLevel && showDataTab) {
        fetchIndividuals(selectedGroupId);
      }
    }, [tabIndex, isLastLevel, showDataTab, selectedGroupId]);



    const getIconByGroupType = (groupType) => {
      const iconConfig = {
        0: { Icon: AddIcon, color: '#324452' },
        1: { Icon: TurnLeftIcon, color: '#324452' },
        3: { Icon: FolderIcon, color: '#FFD580' },
        4: { Icon: FolderIcon, color: '#65a765' },
        5: { Icon: FolderIcon, color: '#87CEEB' },
      };

      const { Icon, color } = iconConfig[groupType] || { Icon: FolderIcon, color: '#FF941C73' };

      return <Icon style={{ fontSize: 120, color: color }} />;
    };

    // async function handleFileUpload() {
    //   const input = document.createElement('input');
    //   input.type = 'file';
    //   input.accept = '.xlsx,.xls,.csv';
    
    //   input.onchange = async (event) => {
    //     const file = event.target.files[0];
    //     if (!file) {
    //       console.error('No file selected');
    //       return;
    //     }
    
    //     const formData = new FormData();
    //     formData.append('file', file);
    //     formData.append('group_id', selectedGroupId); // Using the selectedGroupId from the component's state
    
    //     try {
    //       const response = await axios.post(`${APIURL}/data_collection/api/upload-individuals/`, formData, {
    //         headers: {
    //           'Content-Type': 'multipart/form-data',
    //           'Authorization': `Token ${token}` // Using the token from localStorage
    //         }
    //       });
    
    //       if (response.data.status === 'success') {
    //         console.log(response.data.message);
    //         alert(response.data.message); // Show success message to the user
    //         // Optionally, you can update the contacts state here if needed
    //         fetchIndividuals(selectedGroupId); // Refresh the individuals list
    //       } else {
    //         console.error('Upload failed:', response.data.message);
    //         alert(`Upload failed: ${response.data.message}`); // Show error message to the user
    //       }
    //     } catch (error) {
    //       console.error('Error uploading file:', error);
    //       alert('Error uploading file. Please try again.'); // Show general error message
    //     }
    //   };
    
    //   input.click();
    // }

    return (
      <Container>
        <MobileHeader>
          <ToggleButton left onClick={toggleLeftSidebar}>
            {leftSidebarOpen ? <CloseIcon /> : <MenuIcon />}
          </ToggleButton>
          <HeaderLogo>
            <img src="/images/company-logo.png" alt="Company Logo" />
            <HeaderTitle>CODEDC</HeaderTitle>
          </HeaderLogo>
        </MobileHeader>
        <SidebarContainer open={leftSidebarOpen}>
          <SidebarSection>
            <CompanyLogo>
              <img src="/images/company-logo.png" alt="Company Logo" />
              <CompanyText>CODEDC</CompanyText>
            </CompanyLogo>
            <UserManagement>User Management</UserManagement>
          </SidebarSection>
          <FolderView 
            treeData={treeData} 
            handleAddNewClick={handleAddNewClick} 
            onNodeSelect={handleNodeSelect} 
            expanded={expanded} 
            onToggle={handleToggle} 
          />
        </SidebarContainer>
        <MainContent leftOpen={leftSidebarOpen} >
          <Header>
            <SearchBar>
              <input type="search" placeholder="Search..." aria-label="Search" />
            </SearchBar>
            
            <div className="d-user-info-container">
  <div className="d-user-avatar">
    <div className="d-avatar-circle">
      <PersonIcon className="d-user-icon" />
      <FiberManualRecordIcon className="d-online-icon" style={{ fontSize: 18 }} />
    </div>
  </div>
  <UserInfo>
    <UserDetails>
      <h2>Welcome Back!</h2>
      <p>{storedUserName}</p>
      <p>{storedUserEmail}</p>
    </UserDetails>
    <LogoutButton onClick={handleLogout}>
        <img src= {logout}  alt="Logout" style={{ cursor: 'pointer', width: '35px', height: '40px' ,marginLeft: '20px' , marginTop: '-12px'}} />
      </LogoutButton>
  </UserInfo>
</div>
<style jsx>{`
  .d-user-info-container {
    display: flex;
    align-items: center;
  }
  .d-user-avatar {
    margin-right: 0px;
  }
  .d-avatar-circle {
    position: relative;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .d-user-icon {
    color: #555;
  }
  .d-online-icon {
    position: absolute;
    top: 0;
    right: -2px;
    color: green;
  }
`}</style>
          </Header>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <h2 style={{ color: '#06368B' }}>{capitalizeFirstLetter(selectedGroup)}</h2>
    <h4 style={{ color: '#06368B' }}> - {selectedGroupTypeName}</h4>
    <span style={{ marginLeft: '8px' }}>({capitalizeFirstLetter(fullHierarchy)})</span>
  </div>
  {storedUserRole == 2 && selectedGroupId && (
  <div>
    <button
      onClick={() => handleEditGroup(selectedGroupId)}
      className="group-button edit-group"
      style={{
        padding: '8px 16px',
        marginRight: '10px',
        backgroundColor: '#ffffff',
        color: '#06368B',
        border: '1px solid #06368B',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        width:'120px'
      }}
    >
      Edit Group
    </button>
    <button
      onClick={() => handleDeleteGroup(selectedGroupId)}
      className="group-button delete-group"
      style={{
        padding: '8px 16px',
        backgroundColor: '#ffffff',
        color: '#06368B',
        border: '1px solid #06368B',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        width:'120px'
      }}
    >
      Delete Group
    </button>
    <style jsx>{`
      .group-button:hover {
        background-color: #e6eeff !important;
      }

      .edit-group:hover {
        color: #006400 !important;
        border-color: #006400 !important;
      }

      .delete-group:hover {
        color: #a61b1b !important;
        border-color: #a61b1b !important;
      }
    `}</style>
  </div>
)}
</div> 
          

          <Box sx={{ 
      borderColor: 'divider',
      backgroundColor: '#1877F21A',
      borderRadius: '8px',
      marginBottom: '30px',
      overflowX: 'auto',
      display: 'flex',
      justifyContent: 'space-between'
    }}>
      <Tabs 
        value={tabIndex} 
        onChange={handleTabChange} 
        aria-label="dashboard tabs" 
        variant="scrollable"
      >
        <Tab icon={<SignalCellularAltIcon />} iconPosition="start" label="Reports" />
        {isLastLevel && showDataTab && <Tab icon={<AddchartIcon />} iconPosition="start" label="Data" />}
        {isLastLevel && showSurveyTab &&<Tab icon={<EventNoteIcon />} iconPosition="start" label="Surveys" />}
      </Tabs>

      <Tabs 
        value={tabIndexs} 
        onChange={handleTabChanges} 
        aria-label="dashboard right tabs" 
        variant="scrollable"
      >
        <Tab icon={<GroupsIcon />} iconPosition="start" label={childGroupTypeName} />
        <Tab icon={<PeopleIcon />} iconPosition="start" label="Admins" />
      </Tabs>
    </Box>



{tabIndex === 0 && <ReportsTab />}



{ showDataTab && tabIndex === 1 && isLastLevel &&  (
  <div>
   <ButtonContainer>
  <AddButton onClick={handleOpenPollModals}>
    Add File &nbsp; <b>+</b>
  </AddButton>&nbsp;&nbsp;
  <AddButton onClick={openModal}>
    Add Data &nbsp; <b>+</b>
  </AddButton>
</ButtonContainer>
{contacts && contacts.length > 0 && (
<DataTable
  columns={[
    {
      name: 'Sl.No',
      selector: row => row.sl_no,
      sortable: true,
    },
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'Guardian Name',
      selector: row => row.guardian_name,
      sortable: true,
    },
    {
      name: 'Booth No',
      selector: row => row.polling_booth_number,
      sortable: true,
    },
    {
      name: 'House No',
      selector: row => row.house_no,
      sortable: true,
    },
    {
      name: 'House Name',
      selector: row => row.house_name,
      sortable: true,
    },
    {
      name: 'Gender',
      selector: row => row.gender,
      sortable: true,
    },
    {
      name: 'Age',
      selector: row => row.age,
      sortable: true,
    },
    {
      name: 'Epic No',
      selector: row => row.epic_no,
      sortable: true,
    },
  ]}
  data={contacts}
  highlightOnHover
  responsive
  customStyles={customStyles}
/>
)}
     <Dialog open={modalPollOpen} onClose={handleClosePollModals}>
  <DialogTitle>Enter Polling Booth Information</DialogTitle>
  <DialogContent>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {['polling_booth_name', 'polling_booth_number'].map((field) => (
            <Grid item xs={6} key={field}>
              <Typography variant="body1">
                {field.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase())}
                <span style={{ color: 'red' }}> *</span>
              </Typography>
              <TextField
                name={field}
                type="text"
                fullWidth
                variant="outlined"
                value={pollingBoothData[field]}
                onChange={handleInputChange}
                required
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleClosePollModals} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px', marginRight: '10px' }}>
      Cancel
    </Button>
    <Button onClick={handleSubmit} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}>
      Submit
    </Button>
  </DialogActions>
</Dialog>


    <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
      <DialogTitle>Add New Data - {capitalizeFirstLetter(selectedGroup)} </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
         
          {/* <Grid item xs={6}>
            <Typography variant="body1" className="field-label">
              Group Name
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={selectedGroup}
              InputProps={{
                readOnly: true,
              }}
              className="form-field"
            />
          </Grid> */ }
          
          {Object.keys(newContact).filter(field => field !== 'group').map((field, index) => (
  <Grid item xs={6} key={field}>
    <Typography variant="body1" className="field-label">
      {field === 'sl_no' ? 'Sl.No (as per voters list)' :
         field === 'polling_booth_number' ? 'Polling Booth No.' :
         field === 'polling_booth_name' ? 'Polling Booth Name' :field.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
      {['group', 'name', 'gender','house_no', 'house_name','polling_booth_number','polling_booth_name'].includes(field) && 
        <span style={{ color: 'red' }}> *</span>
      }
    </Typography>
    
    {field === 'gender' ? (
      <Select
        name={field}
        fullWidth
        variant="outlined"
        value={newContact[field]}
        onChange={(e) => handleChange(e, setNewContact)}
        className="form-field"
        error={!!formErrors[field]}
        required={['name', 'gender','house_no','house_name','polling_booth_number','polling_booth_name'].includes(field)}
      >
        {GENDER_CHOICES.map((choice) => (
          <MenuItem key={choice} value={Array.isArray(choice) ? choice[0] : choice}>
            {Array.isArray(choice) ? choice[1] : choice}
          </MenuItem>
        ))}
      </Select>
    ) : field === 'age' || field === 'sl_no' || field === 'house_no' || field === 'polling_booth_number'  ? (
      <TextField
        name={field}
        type="text"
        fullWidth
        variant="outlined"
        value={newContact[field]}
        onChange={(e) => {
          const value = e.target.value;
          if (/^\d*$/.test(value)) {
            handleChange(e, setNewContact);
            // Remove error message if input is valid
            setFormErrors(prevErrors => ({
              ...prevErrors,
              [field]: ''
            }));
          } else {
            setFormErrors(prevErrors => ({
              ...prevErrors,
              [field]: `${field.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())} must contain only digits.`
            }));
          }
        }}
        className="form-field"
        error={!!formErrors[field]}
        helperText={formErrors[field]}
        required={['name', 'gender', 'dob', 'age', 'house_no'].includes(field)}
        inputProps={{ pattern: "\\d*" }}
      />
    ) : field === 'name' || field === 'guardian_name'  ? (
      <TextField
        name={field}
        type="text"
        fullWidth
        variant="outlined"
        value={newContact[field]}
        onChange={(e) => {
          const value = e.target.value;
          if (/^[a-zA-Z\s]*$/.test(value)) {
            handleChange(e, setNewContact);
            // Remove error message if input is valid
            setFormErrors(prevErrors => ({
              ...prevErrors,
              [field]: ''
            }));
          } else {
            setFormErrors(prevErrors => ({
              ...prevErrors,
              [field]: `${field.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())} must contain only alphabetic characters and spaces.`
            }));
          }
        }}
        className="form-field"
        error={!!formErrors[field]}
        helperText={formErrors[field]}
        required={['name', 'gender','house_no','house_name','polling_booth_number','polling_booth_name'].includes(field)}
        inputProps={{ pattern: "[a-zA-Z\s]*" }}
      />
    ) :  field === 'dob' ? (
      <TextField
        name={field}
        type="date"
        fullWidth
        variant="outlined"
        value={newContact[field]}
        onChange={(e) => handleChange(e, setNewContact)}
        className="form-field"
        error={!!formErrors[field]}
        helperText={formErrors[field]}
        required={['name', 'gender', 'dob', 'age'].includes(field)}
        InputLabelProps={{
          shrink: true,
        }}
      />
    ):['eligible_to_vote'].includes(field) ? (
      <Select
        name={field}
        fullWidth
        variant="outlined"
        value={newContact[field] ? 'true' : 'false'}
        onChange={(e) => {
          const value = e.target.value === 'true';
          setNewContact(prevState => ({
            ...prevState,
            eligible_to_vote: value
           
          }));
        }}
        className="form-field"
      >
        <MenuItem value="false">No</MenuItem>
        <MenuItem value="true">Yes</MenuItem>
      </Select>
    // ) : field === 'eligible_to_vote' ? (
    //   <Select
    //     name={field}
    //     fullWidth
    //     variant="outlined"
    //     value={newContact[field]}
    //     onChange={(e) => handleChange(e, setNewContact)}
    //     className="form-field"
    //     error={!!formErrors[field]}
    //     required={true}
    //   >
    //     {VOTE_CHOICES.map((choice) => (
    //       <MenuItem key={choice} value={choice}>{choice.charAt(0).toUpperCase() + choice.slice(1)}</MenuItem>
    //     ))}
    //   </Select>
    ) : field === 'pension' ? (
      <Select
        name={field}
        fullWidth
        variant="outlined"
        value={newContact[field]}
        onChange={(e) => handleChange(e, setNewContact)}
        className="form-field"
        error={!!formErrors[field]}
        required={true}
      >
        {VOTE_CHOICES.map((choice) => (
          <MenuItem key={choice} value={choice}>{choice.charAt(0).toUpperCase() + choice.slice(1)}</MenuItem>
        ))}
      </Select>
    ) : field === 'marital_status' ? (
      <Select
        name={field}
        fullWidth
        variant="outlined"
        value={newContact[field]}
        onChange={(e) => handleChange(e, setNewContact)}
        className="form-field"
        error={!!formErrors[field]}
        required={true}
      >
        {MARITAL_STATUS_CHOICES.map((choice) => (
          <MenuItem key={choice} value={choice}>{choice.charAt(0).toUpperCase() + choice.slice(1)}</MenuItem>
        ))}
      </Select>
    ) : (
      <TextField
        name={field}
        type={
          field === 'email' ? 'email' : 
          field === 'sl_no' || field === 'house_no' || field === 'aadhaar_number' ? 'number' : 
          'text'
        }
        inputProps={
          field === 'sl_no' || field === 'house_no' || field === 'aadhaar_number'
            ? { step: 1, min: 1 } 
            : {}
        }
        fullWidth
        variant="outlined"
        
        onChange={(e) => handleChange(e, setNewContact)}
        className="form-field"
        error={!!formErrors[field]}
        helperText={formErrors[field]}
        required={['name', 'gender','house_no','house_name','polling_booth_number','polling_booth_name'].includes(field)}
      />
    )}
  </Grid>
))}

    </Grid>
    {errorMessage && (
      <Typography variant="body2" color="error" style={{ marginBottom: '10px' }}>
        {errorMessage}
      </Typography>
    )}
  </DialogContent>
  <DialogActions>
  <Button onClick={() => setModalOpen(false)} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}>
      Cancel
    </Button>
    <Button onClick={addRow} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}>
      Submit
    </Button>
    
  </DialogActions>
</Dialog>

{/* <Dialog open={editModalOpen} onClose={() => setEditModalOpen(false)}>
  <DialogTitle>Edit Contact</DialogTitle>
  <DialogContent>
    <Grid container spacing={2}>
      {currentContact && Object.keys(currentContact).filter(key => key !== 'index').map((field) => (
        <Grid item xs={6} key={field}>
          <Typography variant="body1" className="field-label">
            {field.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
            {['sl_no', 'name', 'gender', 'dob','guardian_name','epic_no'].includes(field) && 
              <span style={{ color: 'red' }}> *</span>
            }
          </Typography>
          {field === 'gender' ? (
            <Select
              name={field}
              fullWidth
              variant="outlined"
              value={currentContact[field]}
              onChange={(e) => handleChange(e, setCurrentContact)}
              className="form-field"
              
              required={['sl_no', 'name', 'gender', 'dob','epic_no'].includes(field)}
            >
              {GENDER_CHOICES.map((choice) => (
                <MenuItem key={choice} value={Array.isArray(choice) ? choice[0] : choice}>
                  {Array.isArray(choice) ? choice[1] : choice}
                </MenuItem>
              ))}
            </Select>
         
             ) : field === 'eligible_to_vote' ? (
              <Select
                name={field}
                fullWidth
                variant="outlined"
                value={currentContact[field]}
                onChange={(e) => handleChange(e, setCurrentContact)}
                className="form-field"
                error={!currentContact[field]}
                required={true}
              >
                {VOTE_CHOICES.map((choice) => (
                  <MenuItem key={choice} value={choice}>{choice.charAt(0).toUpperCase() + choice.slice(1)}</MenuItem>
                ))}
              </Select>
              ) : field === 'pension ' ? (
                <Select
                  name={field}
                  fullWidth
                  variant="outlined"
                  value={currentContact[field]}
                  onChange={(e) => handleChange(e, setCurrentContact)}
                  className="form-field"
                  error={!currentContact[field]}
                  required={true}
                >
                  {PENSION_CHOICES.map((choice) => (
                    <MenuItem key={choice} value={choice}>{choice.charAt(0).toUpperCase() + choice.slice(1)}</MenuItem>
                  ))}
                </Select>
                ) : field === 'marital_status' ? (
                  <Select
                    name={field}
                    fullWidth
                    variant="outlined"
                    value={currentContact[field]}
                    onChange={(e) => handleChange(e, setCurrentContact)}
                    className="form-field"
                    error={!currentContact[field]}
                    required={true}
                  >
                    {MARITAL_STATUS_CHOICES.map((choice) => (
                      <MenuItem key={choice} value={choice}>{choice.charAt(0).toUpperCase() + choice.slice(1)}</MenuItem>
                    ))}
                  </Select>
          ) : (
            <TextField
              name={field}
              type={
                field === 'dob' ? 'date' : 
                field === 'email' ? 'email' : 
                field === 'house_no' || field === 'sl_no' || field === 'aadhaar_number' ? 'number' : 
                'text'
              }
              fullWidth
              variant="outlined"
              value={currentContact[field]}
              onChange={(e) => handleChange(e, setCurrentContact)}
              className="form-field"
              error={['sl_no', 'name', 'gender', 'dob','epic_no'].includes(field) && !currentContact[field]}
              helperText={['sl_no', 'name', 'gender', 'dob','epic_no'].includes(field) && !currentContact[field] ? 'This field is required' : ''}
              required={['sl_no', 'name', 'gender', 'dob','epic_no'].includes(field)}
            />
          )}
        </Grid>
      ))}
    </Grid>
    {errorMessage && (
      <Typography variant="body2" color="error" style={{ marginBottom: '10px' }}>
        {errorMessage}
      </Typography>
    )}
  </DialogContent>
  <DialogActions>
  <Button onClick={() => setEditModalOpen(false)} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}>
      Cancel
    </Button>
    <Button onClick={updateRow} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}>
      Update
    </Button>
    
  </DialogActions>
</Dialog> */}
           </div>
          )}
        

        { showSurveyTab && tabIndex === 2 && isLastLevel && <SurveysTab   
        selectedGroupId={selectedGroupId}
  selectedGroup={selectedGroup}
  token={token}
  handleTeamMemberClick={handleTeamMemberClick}
  handleNodeSelect={handleNodeSelect}
  teamMembers={teamMembers}
  
        />}

        {tabIndexs === 0 && (
  <GroupDataTab
    selectedGroup={selectedGroup}
    teamMembers={teamMembers}
    handleTeamMemberClick={handleTeamMemberClick}
    getIconByGroupType={getIconByGroupType}
    handleEditGroup={handleEditGroup}
    handleDeleteGroup={handleDeleteGroup}
  
    
    
  />
)}

{tabIndexs === 1 && (
  <MembersTab
    users={users}
    addUserError={addUserError}
    newUser={newUser}
    storedUserRole={storedUserRole}
    selectedGroupId={selectedGroupId}
    selectedGroup={selectedGroup}
    selectedGroupType={selectedGroupType}
    isAddUserPopupOpen={isAddUserPopupOpen}
    isUserDetailsPopupOpen={isUserDetailsPopupOpen}
    handleAddUserInputChange={handleAddUserInputChange}
    handleAddUser={handleAddUser}
    profileFields={profileFields}    
    profileEditPopup={profileEditPopup}   
    setPasswordPopup={setPasswordPopup}
    setAddUserError={setAddUserError}       
    handleMoreVertIconClick={handleMoreVertIconClick}
    handleUserDetailsPopupClose={handleUserDetailsPopupClose}
    setIsAddUserPopupOpen={setIsAddUserPopupOpen}
    handleProfileFieldChange={handleProfileFieldChange}
    handleProfileEditOpen={handleProfileEditOpen}
    handleSetPasswordOpen={handleSetPasswordOpen}
    setIsSetPasswordPopupOpen={setIsSetPasswordPopupOpen}
    currentPassword={currentPassword}
  setCurrentPassword={setCurrentPassword}
  newpasswordnew={newpasswordnew}
  setNewPasswordNew={setNewPasswordNew}
  confirmPasswordconfirm={confirmpasswordconfirm}
  setConfirmPasswordConfirm={setConfirmPasswordConfirm}
  handleSavePassword={handleSavePassword}
  selectedUser={selectedUser}
  setSelectedUser={setSelectedUser}
  isActionDialogOpen={isActionDialogOpen}
  setIsActionDialogOpen={setIsActionDialogOpen}
  selectedAction={selectedAction}
  setSelectedAction={setSelectedAction}
  handleAction={handleAction}
  setIsUserDetailsPopupOpen={ setIsUserDetailsPopupOpen}
  />
)}



        </MainContent>
                
        <Dialog open={isPopupOpenNew} onClose={handlePopupClose}>
        <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
            {responseMessage ? (
              <Typography variant="body1">{responseMessage}</Typography>
            ) : (
              <TextField
                autoFocus
                margin="dense"
                label="Name"
                type="text"
                fullWidth
                variant="standard"
                value={newEntry}
                onChange={handleNameChange}
              />
            )}
          </DialogContent>
          <DialogActions>
            {responseMessage ? (
              <Button onClick={handlePopupClose}>Close</Button>
            ) : (
              <>
                <Button onClick={handlePopupClose}>Cancel</Button>
                <Button onClick={handleAdd}>Add</Button>
              </>
            )}
          </DialogActions>
        </Dialog>
        
      </Container>
      
    );
}

const DeleteIcon = styled.span`
  cursor: pointer;
  margin-left: 10px;
`;
const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  background-color:#E4DD9F85;
  border-radius:15px;
;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  min-width: 600px;
`;

const ButtonContainer = styled.div`
  background-color: #6F89B7;
  padding: 13px;
  border-radius: 15px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2px;
`;
const TableCell = styled.td`
  border: 4px solid #FFFFFF;
  padding: 16px;
`;

const TableRow = styled.tr`
  
    background-color: #E4DD9F85;


`;

const EditIcon = styled.span`
  cursor: pointer;
`;


const AddButton = styled.button`
  padding: 10px 30px;
  background-color: #FFFFFF;
  color: #133E88;
  border: none;
  border-radius: 34px;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  &:hover {
    background-color: #cccccc;
  }
`;

const Container = styled.div`
  background-color: #fff;
  display: flex;
  position: relative;

`;

const MobileHeader = styled.header`
  display: none;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
  padding: 10px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;

  @media (max-width: 1000px) {
    display: flex;
  }
`;

const HeaderTitle = styled.h1`
  color: #2456ae;
  margin: 0;
  font-size: 18px;
`;
const HeaderLogo = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 50px;
    margin-right: 20px;
  }
`;

const MainContent = styled.main`
  display: flex;
  flex-direction: column;
  width: 77%;
  padding: 37px 20px;
  transition: width 0.3s;

  @media (max-width: 1000px) {
    width: 100%;
    margin-left: ${props => props.leftOpen ? '250px' : '0'};
    margin-right: ${props => props.rightOpen ? '250px' : '0'};
  }
`;

const SidebarContainer = styled.aside`
  display: flex;
  flex-direction: column;
  width: 23%;
  height: 100vh;
  background-color: rgba(24, 119, 242, 0.02);
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.15);
  padding: 35px 20px 80px;
  transition: transform 0.3s ease-in-out;

  @media (max-width: 1000px) {
    position: fixed;
    left: 0;
    top: 0;
    width: 200px;
    height: 100%;
    z-index: 999;
    transform: ${props => props.open ? 'translateX(0)' : 'translateX(-100%)'};
  }
  @media (max-width: 1000px) {
    margin-top: 50px;
  }
`;


const SidebarSection = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

const CompanyLogo = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 50px;
    margin-left: 20px;
  }
  @media (max-width: 1000px) {
    display: none;
  }
`;

const CompanyText = styled.span`
  font-family: Inter, sans-serif;
  flex-grow: 1;
  font-weight: 700;
  color: #2456ae;
  margin-left: -45px;
  @media (max-width: 1100px) {
    margin-left: -15px;
  }
  @media (max-width: 1000px) {
    display: none;
  }
`;

const UserManagement = styled.h3`
  margin-top: 40px;
  text-align: center;
  padding-left: 

25px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size: 17px;
`;


const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  @media (max-width: 1000px) {
    margin-top: 50px;
  }
`;

const SearchBar = styled.div`
  input {
    padding: 12px 26px;
    border-radius: 15px;
    border: 1px solid rgba(217, 217, 217, 1);
    font-size: 14px;
    font-weight: 500;
    color: #afafaf;
  }
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 1);
  }
`;

const UserDetails = styled.div`
  margin-left: 10px;

  h2 {
    font-size: 10px;
    font-weight: 400;
    color: #5d5d5d;
  }

  p {
    margin-top: 6px;
    font-size: 12px;
  }
`;



export default Dashboard;